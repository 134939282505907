import React from 'react'

import { themeColors } from 'styles/theme'
import { useTheme } from 'styled-components'

import { ReactComponent as addcard } from 'assets/icons/add-card.svg'
import { ReactComponent as gear } from 'assets/icons/gear.svg'
import { ReactComponent as addcustomer } from 'assets/icons/add-customer.svg'
import { ReactComponent as addregister } from 'assets/icons/add-register.svg'
import { ReactComponent as errorcirclefilled } from 'assets/icons/error-circle-filled.svg'
import { ReactComponent as alertcircleoutline } from 'assets/icons/alert-circle-outline.svg'
import { ReactComponent as alertcirclefilled } from 'assets/icons/alert-circle-filled.svg'
import { ReactComponent as ame } from 'assets/icons/ame.svg'
import { ReactComponent as antecipatioIlustration } from 'assets/icons/antecipation-ilustration.svg'
import { ReactComponent as arrowaltright } from 'assets/icons/arrowaltright.svg'
import { ReactComponent as arrowBack } from 'assets/icons/arrow-back.svg'
import { ReactComponent as arrowdown } from 'assets/icons/arrowdown.svg'
import { ReactComponent as arrowleft } from 'assets/icons/arrowleft.svg'
import { ReactComponent as arrowright } from 'assets/icons/arrowright.svg'
import { ReactComponent as arrowspinner } from 'assets/icons/arrow-spinner.svg'
import { ReactComponent as arrowup } from 'assets/icons/arrowup.svg'
import { ReactComponent as backbtn } from 'assets/icons/back-btn.svg'
import { ReactComponent as bank } from 'assets/icons/bank.svg'
import { ReactComponent as calendar } from 'assets/icons/calendar.svg'
import { ReactComponent as checkmarkcirclefilled } from 'assets/icons/checkmark-circle-filled.svg'
import { ReactComponent as checkmarkcircleoutline } from 'assets/icons/checkmark-circle-outline.svg'
import { ReactComponent as checkmarksquaredoutline } from 'assets/icons/checkmark-squared-outline.svg'
import { ReactComponent as cielo } from 'assets/icons/cielopay.svg'
import { ReactComponent as circlefilleduser } from 'assets/icons/circle-filled-user.svg'
import { ReactComponent as clipboard } from 'assets/icons/copy-outline.svg'
import { ReactComponent as clock } from 'assets/icons/clock.svg'
import { ReactComponent as close } from 'assets/icons/close-outline.svg'
import { ReactComponent as closesquare } from 'assets/icons/close-square.svg'
import { ReactComponent as closecircle } from 'assets/icons/close-circle.svg'
import { ReactComponent as coinpayments } from 'assets/icons/coinpayments.svg'
import { ReactComponent as coin } from 'assets/icons/coin.svg'
import { ReactComponent as company } from 'assets/icons/company.svg'
import { ReactComponent as customer } from 'assets/icons/customer.svg'
import { ReactComponent as display } from 'assets/icons/display.svg'
import { ReactComponent as depersonify } from 'assets/icons/depersonify.svg'
import { ReactComponent as dotsVertical } from 'assets/icons/dots-vertical.svg'
import { ReactComponent as dotsHorizontal } from 'assets/icons/dots-horizontal.svg'
import { ReactComponent as emailoutline } from 'assets/icons/email-outline.svg'
import { ReactComponent as exit } from 'assets/icons/exit.svg'
import { ReactComponent as eyeoff } from 'assets/icons/eye-off-outline.svg'
import { ReactComponent as eyeon } from 'assets/icons/eye-outline.svg'
import { ReactComponent as file } from 'assets/icons/file.svg'
import { ReactComponent as fileadd } from 'assets/icons/file-add.svg'
import { ReactComponent as fullShipayLogo } from 'assets/icons/shipay-logo-full.svg'
import { ReactComponent as home } from 'assets/icons/home.svg'
import { ReactComponent as infooutline } from 'assets/icons/info-outline.svg'
import { ReactComponent as logoRede } from 'assets/icons/logo-rede.svg'
import { ReactComponent as logoSpacePay } from 'assets/logos/logo-spacepay.svg'
import { ReactComponent as mercadopago } from 'assets/icons/mercadopago.svg'
import { ReactComponent as monitoroutline } from 'assets/icons/monitor-outline.svg'
import { ReactComponent as ninetyninepay } from 'assets/icons/ninetyninepay.svg'
import { ReactComponent as options } from 'assets/icons/options.svg'
import { ReactComponent as pagseguro } from 'assets/icons/pagbank.svg'
import { ReactComponent as payments } from 'assets/icons/payments.svg'
import { ReactComponent as pencil } from 'assets/icons/pencil.svg'
import { ReactComponent as pencilUnderline } from 'assets/icons/pencil-underline.svg'
import { ReactComponent as person } from 'assets/icons/person.svg'
import { ReactComponent as personify } from 'assets/icons/personify.svg'
import { ReactComponent as picpay } from 'assets/icons/picpay.svg'
import { ReactComponent as pinoutline } from 'assets/icons/pin-outline.svg'
import { ReactComponent as pix } from 'assets/icons/pix.svg'
import { ReactComponent as pixbb } from 'assets/icons/pixbancodobrasil.svg'
import { ReactComponent as pixbradesco } from 'assets/icons/pixbradesco.svg'
import { ReactComponent as pixcard } from 'assets/icons/pix-card.svg'
import { ReactComponent as pixcarddiagonal } from 'assets/icons/pix-card-diagonal.svg'
import { ReactComponent as pixicon } from 'assets/icons/pix-icon.svg'
import { ReactComponent as pixiconoutline } from 'assets/icons/pix-icon-outline.svg'
import { ReactComponent as pixitau } from 'assets/icons/pixitau.svg'
import { ReactComponent as pixlogowithlettering } from 'assets/icons/pix-logo-with-lettering.svg'
import { ReactComponent as pixmercadopago } from 'assets/icons/pixmercadopago.svg'
import { ReactComponent as pixpagseguro } from 'assets/icons/pixpagbank.svg'
import { ReactComponent as pixpicpay } from 'assets/icons/pixpicpay.svg'
import { ReactComponent as pixportobank } from 'assets/icons/pixportobank.svg'
import { ReactComponent as pixsantander } from 'assets/icons/pixsantander.svg'
import { ReactComponent as questionmarkcircle } from 'assets/icons/questionmark-circle.svg'
import { ReactComponent as register } from 'assets/icons/register.svg'
import { ReactComponent as search } from 'assets/icons/search.svg'
import { ReactComponent as shipaylogo } from 'assets/icons/shipay-logo.svg'
import { ReactComponent as shipaylogotitle } from 'assets/icons/shipay-logo-title.svg'
import { ReactComponent as spinner } from 'assets/icons/spinner.svg'
import { ReactComponent as store } from 'assets/icons/store.svg'
import { ReactComponent as tecban } from 'assets/icons/tecban.svg'
import { ReactComponent as trash } from 'assets/icons/trash.svg'
import { ReactComponent as upload } from 'assets/icons/upload.svg'
import { ReactComponent as paper } from 'assets/icons/paper.svg'
import { ReactComponent as addpaper } from 'assets/icons/add-paper.svg'
import { ReactComponent as downloadarrow } from 'assets/icons/download-arrow.svg'
import { ReactComponent as warning } from 'assets/icons/warning.svg'
import { ReactComponent as statuscircle } from 'assets/icons/status-circle.svg'
import { ReactComponent as building } from 'assets/icons/building.svg'
import { ReactComponent as register2 } from 'assets/icons/register-2.svg'
import { ReactComponent as plusSign } from 'assets/icons/plus-sign.svg'
import { ReactComponent as settings } from 'assets/icons/settings.svg'
import { ReactComponent as colorpicker } from 'assets/icons/colorpicker.svg'
import { ReactComponent as union } from 'assets/icons/union.svg'
import { ReactComponent as network } from 'assets/icons/network.svg'
import { ReactComponent as colordropper } from 'assets/icons/color-dropper.svg'
import { ReactComponent as cashinarrowdown } from 'assets/icons/cashin-arrowdown.svg'
import { ReactComponent as cashoutarrowup } from 'assets/icons/cashout-arrowup.svg'
import { ReactComponent as uploadimage } from 'assets/icons/upload-image.svg'
import { ReactComponent as cashin } from 'assets/icons/cashin.svg'
import { ReactComponent as cashout } from 'assets/icons/cashout.svg'
import { ReactComponent as checkmark } from 'assets/icons/checkmark.svg'
import { ReactComponent as returnarrow } from 'assets/icons/return-arrow.svg'
import { ReactComponent as accountwallet } from 'assets/icons/account-wallet.svg'
import { ReactComponent as largearrow } from 'assets/icons/large-arrow.svg'
import { ReactComponent as moneysign } from 'assets/icons/money-sign.svg'
import { ReactComponent as itauiconpix } from 'assets/icons/itau-icon-pix.svg'
import { ReactComponent as bbiconpix } from 'assets/icons/bb-icon-pix.svg'
import { ReactComponent as cycleiconpix } from 'assets/icons/cycle-icon-pix.svg'
import { ReactComponent as foursquare } from 'assets/icons/four-square.svg'
import { ReactComponent as pluscircle } from 'assets/icons/plus-circle.svg'
import { ReactComponent as rgdoc } from 'assets/icons/rg-cashout-onboarding.svg'
import { ReactComponent as cnhdoc } from 'assets/icons/cnh-cashout-onboarding.svg'
import { ReactComponent as charge } from 'assets/icons/charge.svg'
import { ReactComponent as phone } from 'assets/icons/phone.svg'
import { ReactComponent as checkcustomer } from 'assets/icons/check-customer.svg'
import { ReactComponent as uncheckcustomer } from 'assets/icons/uncheck-customer.svg'
import { ReactComponent as pausecircle } from 'assets/icons/pause-circle.svg'
import { ReactComponent as playcircle } from 'assets/icons/play-circle.svg'

export const dict = {
  returnarrow,
  moneysign,
  largearrow,
  building,
  register2,
  paper,
  gear,
  downloadarrow,
  addcard,
  addcustomer,
  addregister,
  errorcirclefilled,
  alertcircleoutline,
  alertcirclefilled,
  ame,
  antecipatioIlustration,
  arrowaltright,
  arrowBack,
  arrowdown,
  arrowleft,
  arrowright,
  arrowspinner,
  arrowup,
  addpaper,
  backbtn,
  bank,
  calendar,
  cashin,
  cashout,
  checkmarkcirclefilled,
  checkmarkcircleoutline,
  checkmarksquaredoutline,
  checkmark,
  cielo,
  circlefilleduser,
  clipboard,
  clock,
  close,
  closesquare,
  closecircle,
  coinpayments,
  company,
  customer,
  depersonify,
  display,
  dotsVertical,
  dotsHorizontal,
  emailoutline,
  exit,
  eyeoff,
  eyeon,
  file,
  fileadd,
  fullShipayLogo,
  home,
  infooutline,
  logoRede,
  logoSpacePay,
  mercadopago,
  monitoroutline,
  ninetyninepay,
  options,
  pagseguro,
  payments,
  pencil,
  pencilUnderline,
  person,
  personify,
  picpay,
  pinoutline,
  pix,
  pixbb,
  pixbradesco,
  pixcard,
  pixcarddiagonal,
  pixicon,
  pixiconoutline,
  pixitau,
  pixlogowithlettering,
  pixmercadopago,
  pixpagseguro,
  pixpicpay,
  pixportobank,
  pixsantander,
  questionmarkcircle,
  register,
  search,
  shipaylogo,
  shipaylogotitle,
  spinner,
  store,
  tecban,
  trash,
  upload,
  warning,
  statuscircle,
  plusSign,
  coin,
  settings,
  colorpicker,
  union,
  network,
  colordropper,
  uploadimage,
  cashinarrowdown,
  cashoutarrowup,
  accountwallet,
  rgdoc,
  cnhdoc,
  itauiconpix,
  bbiconpix,
  cycleiconpix,
  foursquare,
  pluscircle,
  charge,
  phone,
  checkcustomer,
  uncheckcustomer,
  pausecircle,
  playcircle,
}

export type IconName = keyof typeof dict
export type ThemeColorNames = keyof typeof themeColors

interface IProps {
  name: IconName
  color?: ThemeColorNames
  fill?: ThemeColorNames
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  name,
  color = 'gray1',
  fill,
  width = 21,
  ...rest
}: IProps & React.SVGAttributes<SVGElement>) => {
  const theme = useTheme()
  const Icon = dict[name]
  return Icon ? (
    <Icon
      role="img"
      aria-label={name}
      fill={fill ? theme.colors[fill] : theme.colors['gray2']}
      width={width}
      {...rest}
    />
  ) : null
}
