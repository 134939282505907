import React from 'react'
import * as s from './styles'
import { useHistory } from 'react-router-dom'
import { SystemWalletsInterface } from 'store/modules/systemWallets/types'
import { FeaturesSelectionSection } from './FeaturesSelection'
import Loading from './Loading'
import Success from './Success'
import RegisterForm from './RegisterForm'
import { PixWSInterface } from 'services/pixWalletSettings'
import { isNotEmpty } from 'helpers/validators'
import { normalize } from 'helpers/pix'
import { useSSE } from 'hooks/utils'
import useGetWalletSettingsModalConfig from 'hooks/useGetWalletSettingModalConfig'
import Error from './Error'
import BanklineItauTutorial from './BanklineItauTutorial'
import { post } from 'services/api'
import customerService from 'services/customer'
import { AxiosError } from 'axios'
import { StoreAssociation } from './StoreAssociation'
import useGetStores from 'hooks/useGetStores'
import StoresAssociationError from './StoresAssociationError'

interface IProps {
  isOpen: string
  handleClose: () => void
  wallet: Partial<SystemWalletsInterface>
}

interface Field {
  name: string
  message: string
}

interface Fields {
  fields: Field[]
}

interface SectionErrorField {
  property: string
  message: string
}

interface ErrorFromDTO {
  message: [SectionErrorField[]]
  error: string
  statusCode: number
}

type ErrorFromBFF = Fields | ErrorFromDTO

interface StepHeaderProps {
  stepName: string
  active: boolean
}

export interface FormStateProps {
  name: string
  cpf_cnpj: string
  dict_key: string
  pix_dict_key_type?: string
  bank_branch: string
  bank_account: string
  wallet_code: string
}

export interface Stores {
  cnpj_cpf: string
  destination_account_id: string
  id: string
  name: string
}
export interface StoreAssociationProps {
  stores: Array<Stores> | []
  selectedStores: string[]
  setSelectedStores: React.Dispatch<React.SetStateAction<string[]>>
}

export const STEPS_INITIAL_STATE = {
  featuresSelections: false,
  bankline: false,
  loading: false,
  success: false,
  registerForm: false,
  alreadyExists: false,
  apiError: false,
  storeAssociation: false,
  storeAssociationError: false,
}

export type Steps = typeof STEPS_INITIAL_STATE

const STEPS_HEADER_INITIAL_STATE = [
  {
    stepName: 'Funcionalidades',
    active: true,
  },

  {
    stepName: 'Cadastro',
    active: false,
  },
  {
    stepName: 'Associação',
    active: false,
  },
]

const defaultFields = {
  name: undefined,
}

const defaultFriendlyNames = {
  name: 'Apelido da conta',
}

type StepHeaderName = 'Funcionalidades' | 'Cadastro' | 'Associação'

export const WalletSettingsFormDefault: React.FC<IProps> = ({
  isOpen,
  handleClose,
  wallet,
}) => {
  const history = useHistory()
  const { openConnection, notifications } = useSSE()
  const {
    stores,
    hasError: hasErrorStores,
    isLoading: isLoadingStores,
    loadStores,
  } = useGetStores()

  const [stepsHeader, setStepsHeader] = React.useState<Array<StepHeaderProps>>(
    STEPS_HEADER_INITIAL_STATE
  )

  const [step, setStep] = React.useState({
    ...STEPS_INITIAL_STATE,
    featuresSelections: true,
  })

  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  const [customerUUID, setCustomerUUID] = React.useState<string>('')
  const [walletSettingsUUID, setWalletSettingsUUID] = React.useState<string>('')
  const [selectedStores, setSelectedStores] = React.useState<string[]>([])
  const [notRequestedBindingWithBankLine, setNotRequestedBindingWithBankLine] =
    React.useState(false)

  const infoErrorHandler = (type, errors) => {
    let filteredErrors = {}
    let friendlyNames = {}

    Object.keys(defaultFields).forEach((key) => {
      filteredErrors[key] = errors[key]
      friendlyNames[key] = defaultFriendlyNames[key]
    })

    let toggledSections = modalConfig.sections.filter(
      (section) => section.toggled
    )

    toggledSections.forEach((section) => {
      section.fields.forEach((field) => {
        filteredErrors[field.key] = errors[field.key]
        friendlyNames[field.key] = field.friendlyName
      })
    })
    setFormErrors(filteredErrors)
    const fieldsTranslated = Object.entries(filteredErrors)
      .map((error) => (error[1] ? friendlyNames[error[0]] : undefined))
      .filter((error) => error)
    let requiredFields = ''
    if (fieldsTranslated.length > 2) {
      requiredFields = `${fieldsTranslated
        .slice(0, -1)
        .join(', ')} e ${fieldsTranslated.slice(-1)}`
    } else if (fieldsTranslated.length === 2) {
      requiredFields = `${fieldsTranslated.join(' e ')}`
    } else {
      requiredFields = fieldsTranslated[0]
    }
    if (type === 'emptyFields') {
      setInfoError({
        title: 'Campo(s) obrigatório(s) não preenchido(s)',
        message: (
          <>
            Preencha o(s) campo(s) de <u>{requiredFields}</u> para prosseguir.
          </>
        ),
      })
    }
    if (type === 'invalidFields') {
      if (fieldsTranslated.length >= 2) {
        setInfoError({
          title: 'Informações inválidas.',
          message: (
            <>
              Confira se as informações nos campos de <u>{requiredFields}</u>,
              estão corretas e tente novamente.
            </>
          ),
        })
      } else {
        setInfoError({
          title: 'Informação inválida.',
          message: (
            <>
              Confira se a informação no campo de <u>{requiredFields}</u>, está
              correta e tente novamente.
            </>
          ),
        })
      }
    }
  }

  const [form, setForm] = React.useState<any>()
  const [formErrors, setFormErrors] = React.useState<any>()

  const {
    modalConfig,
    setModalConfig,
    getModalConfig,
    hasError,
    hasSuccess,
    isLoading,
  } = useGetWalletSettingsModalConfig()

  React.useEffect(() => {
    getModalConfig(wallet.wallet_name, ['cashin', 'bank_slip_settings'])
  }, [])

  React.useEffect(() => {
    let bffForm = {}
    let bffFormErrors = {}

    if (step.registerForm) {
      modalConfig.sections?.forEach((section) => {
        section.fields.forEach((field) => {
          switch (field.valueType) {
            case 'string':
              bffForm[field.key] = form[field.key] || ''
              bffFormErrors[field.key] = formErrors[field.key] || ''
              break

            case 'number':
              bffForm[field.key] = null
              bffFormErrors[field.key] = ''
              break

            case 'select':
              bffForm[field.key] = form[field.key] || ''
              break

            case 'boolean':
              bffForm[field.key] = undefined
              bffFormErrors[field.key] = ''
              break
          }
        })
      })
    }

    setForm({ ...bffForm, name: '' })
    setFormErrors({ ...bffFormErrors, name: '' })
    if (window.hj) {
      window.hj('stateChange', `modal_create_pix_${wallet.wallet_name}`)
    }

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_create_pix_${wallet.wallet_name}`,
      })
    }

    return () => {
      setForm({})
      setFormErrors({})
      setInfoError({ title: '', message: <></> })
    }
  }, [])

  React.useEffect(() => {
    if (notifications) {
      const isSuccessful = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
      if (!isSuccessful) {
        const errorFields = {
          client_id: '',
          client_secret: '',
          dict_key: '',
          gw_dev_app_key: '',
        }
        const notificationMessage =
          notifications[customerUUID].message.toLowerCase()
        if (notificationMessage.includes('client_id')) {
          errorFields.client_id = ' '
        }
        if (notificationMessage.includes('client_secret')) {
          errorFields.client_secret = ' '
        }
        if (notificationMessage.includes('dict_key')) {
          errorFields.dict_key = ' '
        }
        if (notificationMessage.includes('gw_dev_app_key')) {
          errorFields.gw_dev_app_key = ' '
        }

        if (notificationMessage.includes('boleto')) {
          modalConfig.sections[1].fields.forEach(
            (field) => (errorFields[field.key] = ' ')
          )
        }

        if (Object.values(errorFields).includes(' ')) {
          infoErrorHandler('invalidFields', errorFields)
          setStep({
            ...STEPS_INITIAL_STATE,
            registerForm: true,
          })
        } else {
          setStep({
            ...STEPS_INITIAL_STATE,
            apiError: true,
          })
        }
      } else {
        const firstKey = Object.keys(notifications)[0]
        const walletSettingsUuid = notifications[firstKey].wallet_settings_uuid
        setWalletSettingsUUID(walletSettingsUuid)
        loadStores({ active: true })
        setStep(handleNextStep(step))
      }
    }
  }, [notifications])

  const handleFeatures = (features: Array<string>) => {
    if (!features) {
      return new Set([])
    }

    return new Set(features)
  }

  const renderHeader = (stepsHeader: Array<StepHeaderProps>) => {
    if (step.loading === true) {
      return <s.HeaderStepLoading />
    }

    return (
      <s.HeaderStepsContainer>
        {stepsHeader.map((stepHeader, index) => {
          return (
            <React.Fragment key={index}>
              <s.HeaderStepWrapper>
                <s.HeaderStepCircle
                  backgroundColor={
                    stepHeader.active ? 'navbarvector1' : 'graytheme8'
                  }
                >
                  <s.NumberStep>{index + 1}</s.NumberStep>
                </s.HeaderStepCircle>
                <s.Text>{stepHeader.stepName}</s.Text>
              </s.HeaderStepWrapper>
            </React.Fragment>
          )
        })}
      </s.HeaderStepsContainer>
    )
  }

  const renderContent = (step: Steps) => {
    if (step.featuresSelections === true) {
      return (
        <FeaturesSelectionSection
          features={handleFeatures(wallet.features)}
          pixProviderName={wallet.wallet_friendly_name.replace('Pix ', '')}
          walletIconUrl={wallet.wallet_icon}
          isLoading={isLoading}
          bankSlipSelected={
            handleFeatures(wallet.features).has('bank_slip_settings')
              ? modalConfig.sections[
                  modalConfig.sections.findIndex(
                    (x) => x.key === 'bank_slip_settings'
                  )
                ]?.toggled
              : false
          }
          setBankSlipSelected={() => toggleSection('bank_slip_settings')}
        />
      )
    }
    if (step.loading === true) {
      return <Loading />
    }
    if (step.success) {
      return (
        <Success
          pspProvider={wallet.wallet_friendly_name.replace('Pix ', '')}
        />
      )
    }

    if (step.registerForm) {
      return (
        <RegisterForm
          formState={[form, setForm]}
          formErrorState={[formErrors, setFormErrors]}
          infoErrorState={[infoError, setInfoError]}
          modalConfigState={[modalConfig, setModalConfig]}
          isFormLoading={isLoading}
          walletIconUrl={wallet.wallet_icon}
          pixProviderName={wallet.wallet_friendly_name.replace('Pix ', '')}
          toggleSection={toggleSection}
          handleClose={handleClose}
        />
      )
    }

    if (step.storeAssociation) {
      return (
        <StoreAssociation
          stores={(stores.data as Array<Stores>) || []}
          selectedStores={selectedStores}
          setSelectedStores={setSelectedStores}
        />
      )
    }

    if (step.storeAssociationError) {
      return (
        <StoresAssociationError
          pspProvider={wallet.wallet_friendly_name.replace('Pix ', '')}
        />
      )
    }

    if (step.bankline) {
      return (
        <BanklineItauTutorial alreadyExists={notRequestedBindingWithBankLine} />
      )
    }

    if (step.alreadyExists) {
      return <Error alreadyExistsError />
    }

    if (step.apiError) {
      return <Error alreadyExistsError={false} />
    }

    return <></>
  }

  const goToStepHeader = (nextStepHeader: StepHeaderName) => {
    const newStepHeader = stepsHeader.map((obj) => {
      if (obj.stepName === nextStepHeader) {
        return { ...obj, active: true }
      }
      return { ...obj, active: false }
    })
    setStepsHeader(newStepHeader)
  }

  const handlePreviousStep = (step: Steps): Steps => {
    if (step.featuresSelections === true) {
      goToStepHeader('Funcionalidades')
      return {
        ...STEPS_INITIAL_STATE,
      }
    }

    if (step.bankline === true) {
      goToStepHeader('Funcionalidades')
      return {
        ...STEPS_INITIAL_STATE,
        featuresSelections: true,
      }
    }

    if (step.registerForm === true) {
      if (wallet.wallet_name === 'itau') {
        return {
          ...STEPS_INITIAL_STATE,
          bankline: true,
        }
      }
      goToStepHeader('Funcionalidades')
      return {
        ...STEPS_INITIAL_STATE,
        featuresSelections: true,
      }
    }
    if (step.loading === true) {
      goToStepHeader('Cadastro')
      return {
        ...STEPS_INITIAL_STATE,
        registerForm: true,
      }
    }

    if (step.alreadyExists === true || step.apiError === true) {
      return {
        ...STEPS_INITIAL_STATE,
        registerForm: true,
      }
    }
  }

  const handleNextStep = (step: Steps): Steps => {
    if (step.featuresSelections === true) {
      goToStepHeader('Cadastro')
      if (wallet.wallet_name === 'itau') {
        return {
          ...STEPS_INITIAL_STATE,
          bankline: true,
        }
      }
      return {
        ...STEPS_INITIAL_STATE,
        registerForm: true,
      }
    }
    if (step.bankline === true) {
      goToStepHeader('Cadastro')
      return {
        ...STEPS_INITIAL_STATE,
        registerForm: true,
      }
    }
    if (step.registerForm === true) {
      if (!isLoading) {
        goToStepHeader('Cadastro')
        return {
          ...STEPS_INITIAL_STATE,
          loading: true,
        }
      }
    }
    if (step.loading === true) {
      goToStepHeader('Associação')
      return {
        ...STEPS_INITIAL_STATE,
        storeAssociation: true,
      }
    }
    if (step.storeAssociation === true) {
      return {
        ...STEPS_INITIAL_STATE,
        success: true,
      }
    }
  }

  const handleNextButtonText = (step: Steps) => {
    if (step.alreadyExists || step.apiError) {
      return 'Fechar'
    }
    if (step.success) {
      return 'Finalizar'
    }
    if (step.storeAssociationError) {
      return 'Ir para Lojas'
    }
    if (step.storeAssociation) {
      return 'Associar'
    }
    return 'Próximo'
  }
  const handlePreviousButtonText = (step: Steps) => {
    if (step.featuresSelections) {
      return 'Fechar'
    }
    if (step.storeAssociation) {
      return 'Pular'
    }
    return 'Voltar'
  }

  const renderButtons = () => {
    if (step.loading === true) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }

    return (
      <s.PreviousNextButtonContainer>
        <s.Div></s.Div>
        <s.PreviousNextButtonWrapper>
          {!step.success && !step.storeAssociationError ? (
            <s.Button
              data-testid="back-button"
              onClick={() => {
                if (step.featuresSelections) {
                  return handleClose()
                }
                if (step.storeAssociation) {
                  return setStep(handleNextStep(step))
                }
                return setStep(handlePreviousStep(step))
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              {step.featuresSelections || step.storeAssociation ? (
                <></>
              ) : (
                <s.Icon name="arrowleft" fill="maincolor" />
              )}
              <s.Text color="graytheme8" type="headline">
                {handlePreviousButtonText(step)}
              </s.Text>
            </s.Button>
          ) : (
            <></>
          )}
          <s.Button
            data-testid="verify-documents-button"
            width="304px"
            height="42px"
            onClick={() => {
              if (step.registerForm) {
                if (!isLoading) {
                  handlePost(form)
                }
              } else if (step.alreadyExists || step.apiError || step.success) {
                handleClose()
              } else if (step.storeAssociation) {
                handlePostStoreAssociation()
              } else if (step.storeAssociationError) {
                history.push(`/lojas`)
              } else {
                setStep(handleNextStep(step))
              }
            }}
          >
            <s.Text margin="0 8px 0 0" color="white" type="headline">
              {handleNextButtonText(step)}
            </s.Text>
            {step.alreadyExists ||
            step.success ||
            step.apiError ||
            step.storeAssociationError ? (
              <></>
            ) : (
              <s.Icon name="arrowright" fill="white" />
            )}
          </s.Button>
        </s.PreviousNextButtonWrapper>
      </s.PreviousNextButtonContainer>
    )
  }

  const toggleSection = (key: string): void => {
    const newConfig = modalConfig
    newConfig.sections.forEach((section) => {
      if (section.key === key) {
        section.toggled = !section.toggled
      }
    })
    setModalConfig({ ...newConfig })
  }

  const handlePost = async (form: FormStateProps) => {
    let payload = {} as Partial<PixWSInterface>
    const errors = { ...formErrors }

    let filteredForm: { cashin?: any; bank_slip_settings?: any } = {
      cashin: {},
    }
    let toggledSections = modalConfig.sections.filter(
      (section) => section.toggled
    )

    filteredForm = toggledSections.reduce((acc, category) => {
      acc[category.key] = category.fields.reduce((fieldsAcc, field) => {
        fieldsAcc[field.key] = form[field.key]
        return fieldsAcc
      }, {})
      return acc
    }, {})

    Object.keys(defaultFields).forEach((key) => {
      filteredForm.cashin[key] = form[key]
    })

    for (const [, value] of Object.entries(filteredForm)) {
      for (const [key, formValue] of Object.entries(value)) {
        if (!errors[key])
          errors[key] = isNotEmpty(formValue as string)
            ? ''
            : 'Campo Obrigatório'
      }
    }

    const errList = Object.values(errors).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      setStep({ ...STEPS_INITIAL_STATE, loading: true })
      const pixKey = normalize(form.dict_key)
      const pixKeyType = form.pix_dict_key_type

      if (filteredForm.bank_slip_settings) {
        filteredForm.bank_slip_settings = {
          ...filteredForm.bank_slip_settings,
          psp_provider: wallet.wallet_name,
        }
      }

      if (filteredForm.cashin) {
        filteredForm.cashin = {
          ...filteredForm.cashin,
          dict_key: pixKey,
          pix_dict_key_type: pixKeyType,
          psp_provider: wallet.wallet_name,
        }
      }

      payload = {
        ...filteredForm,
        wallet_settings: {
          active: true,
          default: false,
          psp_provider: wallet.wallet_name,
          wallet_name: 'pix',
        },
      } as Partial<PixWSInterface>

      await post(`/v2/bff/wallet-settings`, payload)
        .then((response) => {
          customerService.getCurrentCustomer().then((currentCustomerRes) => {
            setCustomerUUID(currentCustomerRes.data.uuid)
            openConnection(currentCustomerRes.data.uuid)
          })
        })
        .catch((error: AxiosError) => {
          const response = (error.response?.data as ErrorFromBFF) || undefined
          const statusCode = error.response?.status || undefined
          const fieldsErrorsFromResponse = {}
          if (statusCode === 400) {
            try {
              if ('fields' in response && response.fields?.length > 0) {
                for (let obj of response.fields) {
                  fieldsErrorsFromResponse[obj.name] = obj.message
                }
              } else if (
                'message' in response &&
                response.message?.length > 0
              ) {
                for (let section of response.message) {
                  for (let input of section) {
                    //BFF errors that comes from invalid requirements from DTO are like 'nameofthesection.field'
                    fieldsErrorsFromResponse[input.property.split('.')[1]] = ' '
                  }
                }
              }
              infoErrorHandler('invalidFields', fieldsErrorsFromResponse)
              setStep({ ...STEPS_INITIAL_STATE, registerForm: true })
            } catch {
              setStep({ ...STEPS_INITIAL_STATE, apiError: true })
            }
          } else if (statusCode === 422) {
            setNotRequestedBindingWithBankLine(true)
            setStep(handlePreviousStep(step))
          } else if (statusCode === 409) {
            setStep({ ...STEPS_INITIAL_STATE, alreadyExists: true })
          } else {
            setStep({ ...STEPS_INITIAL_STATE, apiError: true })
          }
        })
    } else {
      infoErrorHandler('emptyFields', errors)
    }
  }

  const handlePostStoreAssociation = async () => {
    setStep({ ...STEPS_INITIAL_STATE, loading: true })
    await post(`/v1/store-wallets/batch`, {
      wallet_settings: [walletSettingsUUID],
      stores: selectedStores,
    })
      .then((response) => {
        setStep({ ...STEPS_INITIAL_STATE, success: true })
      })
      .catch((error: AxiosError) => {
        setStep({ ...STEPS_INITIAL_STATE, storeAssociationError: true })
      })
  }

  return (
    <>
      <s.Modal
        handleClose={handleClose}
        isOpen={!!isOpen}
        modalSubtitle="Adicionar meio de pagamento"
      >
        <s.ModalContent
          formStep={step.registerForm}
          data-testid="default-modal-content"
        >
          {renderHeader(stepsHeader)}
          {renderContent(step)}
          {renderButtons()}
        </s.ModalContent>
      </s.Modal>
    </>
  )
}
